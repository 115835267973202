<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#005f32"
      spinner="bar-fade-scale"
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex>
        <!-- <v-card> -->
        <v-card-title class="elevation-0">
          <span class="itemHeading">Asset Details</span>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-layout wrap justify-start>
           

          <v-flex xs12 align-self-center fill-height pa-0  >
            <v-card tile flat>
              <v-form v-model="addcat2" ref="addcat2">
                <v-layout wrap>
                  <v-flex xs12 sm6 align-self-center text-left pa-2>
                    <v-text-field
                      v-model="name"
                      label="Asset Name"
                      :rules="[rules.required]"
                      required
                      autofocus
                      hide-details
                      outlined
                      readonly
                      dense
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 align-self-center text-left pa-2>
                     <v-text-field
                      v-model="assetCategoryId.name"
                      label="Asset Category"
                      :rules="[rules.required]"
                      required
                      autofocus
                      hide-details
                      outlined
                      readonly
                      dense
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 align-self-center text-left pa-2>
                    <v-select
                      v-model="assetType"
                      :items="typeList"
                      :rules="[rules.required]"
                      hide-details
                      outlined
                      readonly
                      dense
                      label="Asset type"
                    >
                    </v-select>
                  </v-flex>
                   
                  <v-flex xs12 align-self-center text-left pa-2>
                    <v-textarea
                      v-model="description"
                      label="Description"
                      hide-details
                      readonly
                      outlined
                      type="number"
                      dense
                    ></v-textarea>
                  </v-flex>

                
  
                </v-layout>
              </v-form>
            </v-card>
          </v-flex>
        </v-layout>

        <!-- </v-card> -->
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      showsnackbar: false,
      ServerError: false,
      msg: null,
      pages: 0,
      dialog: false,
      pageCount: 1,
      appLoading: false,
      editedIndex: -1,
      currentPage: 1,

      addcat: false,
      addcat2: false,
      rules: {
        required: (value) => !!value || "Required.",
      },
      radios: null,
      categoryList: [],
      assetCategoryId: null,
      name: "",
      assetType: null,
      typeList: ["Consumable", "Nonconsumable","Semiconsumable"], 
      description: null, 
      quantity: null,
      assetId: null,
      asset:null,
    };
  },
  
  beforeMount() { 
    this.getData(); 
  },

  methods: {
    getData() {
      this.appLoading = true;
      axios({
        url: "/asset/view",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          id: this.$route.query.id
        }
      })
        .then((response) => {
          this.appLoading = false;
          this.asset = response.data.data;
          this.assetCategoryId=response.data.data.assetCategoryId
          this.name=response.data.data.name
          this.description=response.data.data.description
          this.assetType=response.data.data.assetType
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    

      
    

   
  },
};
</script>
